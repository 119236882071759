import axios from 'axios';
import { serverbaselink } from '../../Env_Variables';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const fetchuseauthorization = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/checkauth',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const logincheck = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/logincheck',
        headers: axiosheaders,
        data: axiosdata,
        withCredentials: true,
    });
    return axiosfetch;
};
export const Signup_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/Signup',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const logout = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/generalall/logout',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const submittabexwebsite_contactusform_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/submittabexwebsite_contactusform',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const sendlead_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: 'https://tabexsystem.tabex.clinic' + '/surfer/sendlead',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
