import React, { useState } from 'react';
import { LanguageContext } from '../../../LanguageContext';

export const Landingpagecontext = React.createContext();

export const Landingpagecontext_provider = (props) => {
    const { lang, langdetect } = React.useContext(LanguageContext);

    const [sectionarraycontext, setsectionarraycontext] = useState([
        {
            id: 1,
            sectionname: lang.kindofbusiness,
            icon: 'bx bxs-business',
            sectionToggleActive: 'kindofbusinesssection',
            opensection: 'openkindofbusinesssection',
            ischecked: false,
            path: '/landingpage/business',
        },
        {
            id: 2,
            sectionname: lang.general,
            icon: 'bx bxs-notepad',
            opensection: 'opengeneralsection',
            sectionToggleActive: 'generalsection',
            ischecked: false,
            path: '/landingpage/general',
        },
        {
            id: 3,
            sectionname: lang.contact,
            icon: 'bx bxs-contact',
            opensection: 'opencontactsection',
            sectionToggleActive: 'contactsection',
            ischecked: false,
            path: '/landingpage/contact',
        },
    ]);

    return (
        <Landingpagecontext.Provider
            value={{
                sectionarraycontext,
                setsectionarraycontext,
            }}
        >
            {props.children}
        </Landingpagecontext.Provider>
    );
};

export const Landingpagecontext_consumer = Landingpagecontext.Consumer;
