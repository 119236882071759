import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    passwordsdontmatch: "Password don't match",
                    createyouronlinestorenow: 'Create your online store now',
                    letscreateyourwebsitenow: "Let's create your website",
                    back: 'Back',
                    pricing: 'Pricing',
                    // Home
                    intro: 'Need an online store or reservations system?',
                    intro2: 'Tabex offers you mobile application & website',
                    intro3: 'in one place',
                    yourmobileapp: 'Your mobile application',
                    website: 'website',
                    inoneplace: 'in one place',
                    slogan: 'Create it with the creativity of your own',
                    getstartednow: 'Contact Us And Start Now',
                    // Section 2
                    buildyouronlinestore: 'Build your online store',
                    buildinfoursteps: 'You can now build your online store, mobile application and website from one place in only four steps!',
                    buildstepone: 'Use one of our templates',
                    buildsteptwo: 'Customize it',
                    buildstepthree: 'Publish it',
                    buildstepfour: 'Start selling!',
                    // Section 3
                    geteverythingyouneed: 'Get everything you need',
                    togrowupyourbusiness: 'to grow up your business',
                    createyouronlinestore: 'Create your online store',
                    createyouronlinestoredescription:
                        'Create your website and mobile application within just few steps; Choose a theme, import your products, add the pages you want, customize it and publish it',
                    createyourmobileapp: 'Create your mobile application',
                    createyourmobileappdescription: 'We build apps that add value to your business, and boost your efficiency through cutting edge technologies',
                    customizeit: 'Customize it!',
                    customizeitdescription: 'We offer you a fully customizable website and mobile app builder to make your website and mobile application looks and sounds like you',
                    yourowndashboard: 'Your own dashboard',
                    yourowndashboarddescription: 'Get the right insights to grow up your business and manage it all from one place',
                    getyourdomain: 'Get your domain',
                    getyourdomaindescription: 'Relevant domain names help your customers find you and recognize what you do. We offer you secured domains with 24 hrs support',
                    competitiveprices: 'Competitive prices',
                    competitivepricesdescription: 'We offer you best quality with affordable prices. Choose what suits you the most from our packages and start selling online now',
                    // Section 4
                    alwaysdelivermorethanexpected: 'Always deliver more than expected',
                    mobileapp: 'Mobile application',
                    onlinestore: 'Online store',
                    detailedreport: 'Detailed Reports',
                    // Section 5
                    customizeyouronlinestore: 'Customize your online store',
                    customizeyourpresencedescription: 'Customize your website and mobile application to fit your brand',
                    bilingualinterface: 'Bilingual interface',
                    bilingualinterfacedescription: 'Instant translation for your website and mobile application',
                    stockmanagement: 'Stock/Inventory management',
                    stockmanagementdescription: "Add your products, add products' images, manage your inventory and track your orders",
                    allpaymentsavailable: 'All kind of payments are available',
                    allpaymentsavailabledescription: 'Cash on delivery/credit cards/online cards/....',
                    salesreport: 'Detailed sales report',
                    salesreportdescription: 'Know your sales, monthly revenues, yearly revenues and your expenses',
                    analyticstools: 'Analytics tools',
                    analyticstoolsdescription: 'Know your site visitors and how many visitors do you have.',
                    // Contact us
                    getintouch: 'Get in touch',
                    wedliketohearfromyou: " We'd like to hear from you",
                    // Signup
                    firstname: 'First Name',
                    lastname: 'Last Name',
                    available: 'Available',
                    thisemailalreadyexists: 'This E-mail Already Exists',
                    password: 'Password',
                    confirmpassword: 'Confirm Password',
                    passwrodsdonotmatch: 'Passwrods Do Not Match',
                    createaccount: 'Create Account',
                    bysigningupthismeansyouhaveread: 'By signing up, this means you have read',
                    andacceptedit: ' and accepted it.',
                    email: 'Email',
                    phonenumber: 'Phone number',
                    termsandconditions: 'Terms & Conditions',
                    yourmessage: 'Your message',
                    submit: 'Submit',
                    whoareyou: 'Who are you?',
                    tellusmore: 'Tell us more',
                    joinusand: 'Join us &',
                    expecttheunexpected: 'Expect the unexpected',

                    // Login
                    welcomeback: 'Welcome ',
                    totabexseries: 'to tabex series',
                    login: 'Login',
                    forgotpassword: 'Forgot Password?',
                    wrongemailorpassword: 'Wrong email or password',

                    // LandingPage
                    letstart: "Let's Start!",
                    newwebsite: 'New Website',
                    kindofbusiness: 'Kind Of Business',
                    general: 'General',
                    contact: 'Contact',
                    about: 'About',
                    portfoliowebsite: 'Portfolio Website',
                    store: 'Store',
                    barberorbeautysalon: 'Barber or Beauty Salon',
                    restaurantor_catery: 'Restaurant or Catery',
                    previous: 'Previous',
                    next: 'Next',
                    whatkindofbusinessareyoucreating: 'What Kind Of Business Are You Creating ?',
                    storelogo: 'Store Logo',
                    uploadlogo: 'Upload Logo',
                    storeinformation: 'Store Information',
                    businessname: 'Business Name',
                    bussinessurl: 'Business URL',
                    numberofbranches: 'Number Of Branches',
                    chooselanguage: 'Choose Language',
                    personalinformarion: 'Personal Informarion',

                    businessemail: 'Business Email',
                    contact: 'Contact',
                    socialmediaaccounts: 'Social Media Accounts',
                    facebook: 'Facebook',
                    whatsapp: 'Whatsapp',
                    instagram: 'Instagram',
                    twitter: 'Twitter',
                    telegram: 'Telegram',
                    about: 'About',
                    language: 'Language',
                    address: 'Address',
                    name: 'Name',
                    features: 'Features',

                    // Pricing
                    egp: 'EGP',
                    dollar: 'US Dollar',
                    aed: 'AED',
                    sale: 'Sale',
                    bestqualitywithcompatibleprices: 'Best quality with affordable prices',
                    choosewhatsuitsyou: 'Choose what suits you the best',
                    subscribenow: 'Subscribe now',
                    bronze: 'Bronze',
                    silver: 'Silver',
                    gold: 'Gold',
                    Platinum: 'Platinum',
                    exportorders: 'Export orders',
                    customorderprogress: 'Custom order progress',
                    customcustomerinfo: 'Custom customer information',
                    googleanalytics: 'Google Analytics',
                    customdomainname: 'Custom domain name',
                    support247: '24/7 support',
                    userroles: 'User Roles',
                    liveupdates: 'Live updates',
                    onlinepayment: 'Online payments',
                    multiplelanguages: 'Multiple languages',
                    seo: 'SEO',
                    threelevelsofproducts: '3 levels of products',
                    customizedshippingrates: 'Customized shipping rates',
                    customizedpolicies: 'customized policies',
                    unlimitedpages: 'Unlimited website and mobile application pages',
                    unlimitedpromocodes: 'Unlimited promocodes',
                    unlimitedbranches: 'Unlimited branches',
                    unlimitedusers: 'Unlimited users',
                    unlimitedcustomers: 'Unlimited customers',
                    unlimitedorders: 'Unlimited orders',
                    unlimitedproducts: 'Unlimited products',
                    automatedemailwithyourdomain: 'Automated emails with your domain',
                    freessl: 'Free SSL Certificate',
                    website: 'Website',
                    androidmobileapp: 'Android Mobile Application (Quarterly,Annually)',
                    sociallogin: 'Social login',
                    unlimitedpushnotifications: 'Unlimited Push Notifications (IOS & Android)',
                    vendors: 'Vendors',
                    smsintegration: 'SMS',
                    couriers: 'Couriers',
                    iosmobileapp: 'IOS Mobile Application (Quarterly,Annually)',
                    customermessagessystem: 'Customers Messages System',
                    smsbulk: 'SMS Bulk',
                    popupads: 'Pop-Up Ads',
                    businessemails: 'Business emails',
                    businessmanagementmobileapp: 'Business management mobile application (IOS & Android)',
                    websitebuilder: 'Website builder',
                    mobileappbuilder: 'Mobile application simulator & builder',
                    recommended: 'Recommended',
                    pricingandpackages: 'Packages and pricing',
                    // Features
                    whatmakeusspecial: 'What makes us special',
                    onlinestoreandmobileapp: 'Online store and mobile app (IOS)',
                    dashbaord: 'Dashboard',
                    mobiledashboard: 'Mobile dashboard',
                    websitemobileappbuilder: 'Website and mobile application builder',
                    liveupdates: 'Live updates',
                    customizeyourpresence: 'Customize your online store',
                    // Features description
                    onlinestoreandmobileappdescription1:
                        'With tabex, you will have different designs to choose from what suits you. Once you register, you will be able to sell your products online and specify your payment methods. Moreover, you can choose your Domain name',
                    dashbaorddescription1:
                        'With Tabex, you can add your products by just one click. Also, you can control which products will appear to your customers and specify their prices. Moreover, you can add your shipping companies and vendors and specify shipping rates',
                    mobiledashboarddescription:
                        'You can manage your online store anywhere and anytime through your dashboard application. You will have the right insights and reports to grow up your business and be seen by more customers',
                    websitemobileappbuilderdescription:
                        'Tabex will give you the sense of control that you have never felt with any other tool. You can edit and customize every design you will choose, add pages, remove pages or even change the whole design and make it more like you',
                    liveupdatesdescription: 'You can check any changes or updates you will make on your online store or mobile application in Realtime and it will always look amazing on all devices',
                    smsintegrationdescription: 'With every order, you can send an SMS message with your business name to your customers with to confirm or cancel order',

                    // Login
                    login: 'Login',
                    signup: 'Sign up',
                    arabic: 'Arabic',
                    english: 'English',
                    alreadyhaveannaccount: 'ِAlready have an account?',
                    donthaveaccount: "Don't have an account?",
                    chooseoption: 'Choose Options',
                    mo: 'mo',
                    joinusnow: 'Join us now',
                    pleasefillthemissingfields: 'Please complete the missing fields.',
                    industry: 'Industry',
                    months: 'Months',
                    companyname: 'Company Name',
                    jobtitle: 'Job Title',
                    morethanthousandcustomerstrustus: 'Trusted by more than 1,000 customers',
                    foreaseofusevarietyandcustomerserviceavailablehoursaday: 'For ease of use, variety, and 24 hours customer support.',
                    getanonlinestoreandphoneapplicationeasily: 'Join our customers and get your phone application and online store in less than a week',
                    Morethanpredesignedtemplatessoyoucanmodifythemasyoulikewithouteffort: 'Choose from 20 pre-designed templates, and get your website and application within a week',
                    startnow: 'Start Now',
                    getintouch: 'Get in touch',
                    doyouwanttolearnmoreaboutwhatwedogetintouchandwellcontactyoushortly: 'Do you want to learn more about what we do?',
                    doyouwanttolearnmoreaboutwhatwedogetintouchandwellcontactyoushortly2: "Get in touch and we'll contact you shortly.",

                    themostaffordableforsmallbusinessowners: 'Suitable for small business owners.',
                    everythingyouneedtoempoweryourbusiness: 'Most recommended.',
                    gotiggerneeds: 'Suitable for big businesses.',
                    bedifferent: 'Be different',
                    choosefromourdifferentpredesignedtemplatesandsections: 'Choose from our different pre-designed templates and sections',
                    choosewhatsuitsyoufromourtemplates: 'Choose what suits you from our templates',
                    invalid: 'Invalid',
                    month: 'Months',
                    notes: 'Notes',
                    packages: 'Packages',
                    inlessthanaweek: 'In less than a week ',
                    offerforlimitedtime: 'Offer for a limited time',
                    website: 'Website',
                    webandmob: 'Website & Mobile application',
                    companysize: 'Company Size',
                    startup: 'Startup',
                    small: 'Small',
                    large: 'Large',
                },
                ar: {
                    passwordsdontmatch: 'كلمات السر غير متطابقة',
                    createyouronlinestorenow: 'انشئ متجرك الإلكترونى الان',
                    letscreateyourwebsitenow: 'انشئ موقعك الان',
                    back: 'رجوع',
                    pricing: 'الأسعار',
                    // Home
                    intro: 'عايز تعمل متجر إلكتروني او نظام حجوزات؟',
                    intro2: 'تابكس بتقدملك تطبيق هاتف وموقع إلكتروني',
                    intro3: ' فى مكان واحد',
                    yourmobileapp: 'تطبيق هاتفك',
                    website: 'موقعك الإلكترونى',
                    inoneplace: 'فى مكان واحد',
                    slogan: 'قم بإنشاؤه بإبداعك الخاص',

                    getstartednow: 'اتصل بنا وابدأ الآن',
                    // Section 2
                    buildyouronlinestore: 'ابنى متجرك الإلكترونى',
                    buildinfoursteps: 'دلوقتى تفدر تبنى موقعك الإلكترونى و تطبيق هاتفك فى مكان واحد فى 4 خطوات فقط',
                    buildstepone: 'إستخدم تصميم من تصميماتنا',
                    buildsteptwo: 'عدله زى ما تحب',
                    buildstepthree: 'إبدأ فى نشره',
                    buildstepfour: 'إبدأ البيع!',
                    // Section 3
                    geteverythingyouneed: 'احصل على كل اللى محتاجه',
                    togrowupyourbusiness: 'عشان توسع و تكبر عملك',
                    createyouronlinestore: 'انشئ موقعك الإلكترونى',
                    createyouronlinestoredescription: 'صمم موقع الويب الخاص بك وتطبيق الهاتف في خطوات قليلة؛ اختر التصميم، اضف منتجاتك، اضف الصفحات التي عاوزها، اتحكم فى تصميمها وانشرها',
                    createyourmobileapp: 'إنشئ تطبيق الهاتف',
                    createyourmobileappdescription: 'فريقنا بيستخدم أحدث التقنيات عشان يقدملك تطبيقات تضيف قيمة إلى عملك، وتعزز كفاءتك',
                    customizeit: 'خلّيه شبهك!',
                    customizeitdescription: ' تابكس بتقدملك موقع إلكترونى و تطبيق هاتف تقدر تتحكم فى كل التفاصيل فيهم عشان تخلّيهم شبهك و شبه علامتك التجارية',
                    yourowndashboard: 'لوحة تحكم خاصة بيك',
                    yourowndashboarddescription: ' تابكس بتقدملك كل التقارير اللى هتحتاجها عشان تكبر عملك و تقدر تدير كل حاجه من مكان واحد!',
                    getyourdomain: 'احصل على الDomain الخاص بك',
                    getyourdomaindescription: 'أسماء الDomain اللى ليها علاقة بنوع عملك هتساعد عملائك إنها توصلك بسهولة.تايكس  بنقدملك Domains مؤمنة مع دعم على مدار 24 ساعة',
                    competitiveprices: 'اسعار تنافسية',
                    competitivepricesdescription: 'بنقدملك أفضل جودة و بأسعار معقولة. اختار اللى يناسبك من باقاتنا وابدأ البيع على الإنترنت',
                    // Section 4
                    alwaysdelivermorethanexpected: 'قدّم اكتر من المتوقع',
                    mobileapp: 'تطبيق الهاتف',
                    onlinestore: 'متجر إلكترونى',
                    detailedreport: 'تقارير مفصّلة',
                    // Section 5
                    customizeyouronlinestore: 'تحكم فى متجرك الإلكترونى',
                    customizeyourpresencedescription: 'تحكم فى مظهر موقعك الإلكترونى و تطبيق الهاتف الخاص بك ليناسب علامتك التجاريه',
                    bilingualinterface: 'مترجم باللغه العربيه',
                    bilingualinterfacedescription: 'ترجمه فوريه للموقع الإلكترونى و تطبيق الهاتف',
                    stockmanagement: 'التحكم فى المخزن ',
                    stockmanagementdescription: 'اضف منتجاتك و الصور الخاصه بالمنتجات، تحكم فى مخزنك و راقب الطلبات',
                    allpaymentsavailable: 'جميع طرق الدفع متاحه',
                    allpaymentsavailabledescription: 'الدفع عند الإستلام، بطاقات الإئتمان، بطاقات اونلاين',
                    salesreport: 'تقرير مبيعات مفصل',
                    salesreportdescription: 'تعرف على مبيعاتك، ارباحك الشهريه و السنويه و مصاريفك',
                    analyticstools: 'ادوات التحليل',
                    analyticstoolsdescription: 'تعرف على زبائنك و اعدادهم',
                    // Contact us
                    getintouch: 'تواصل معنا',
                    wedliketohearfromyou: 'حابين نسمع منك',

                    // Signup
                    firstname: 'الإسم الأول',
                    lastname: 'الإسم الأخير',
                    available: 'متاح',
                    password: 'كلمة السر',
                    confirmpassword: 'تأكيد كلمة السر',
                    passwrodsdonotmatch: 'كلمات السر لا تتطابق ',
                    createaccount: 'إنشاء الحساب',
                    bysigningupthismeansyouhaveread: 'من خلال التسجيل هذا يعني أنك قد قرأت',
                    andacceptedit: 'و تم الموافقة عليه.',
                    email: 'البريد الإلكترونى',
                    phonenumber: 'الهاتف',
                    termsandconditions: 'الشروط والأحكام',
                    yourmessage: 'رسالتك',
                    submit: 'إرسل',
                    whoareyou: 'إنت مين؟',
                    tellusmore: 'إحكيلنا اكتر',
                    joinusand: 'إنضم إلينا',
                    expecttheunexpected: 'توقع ما هو غير متوقع',

                    // Login
                    welcomeback: 'اهلا بك',
                    totabexseries: 'فى سلسله Tabex',
                    login: 'تسجيل الدخول',
                    forgotpassword: 'هل نسيت كلمة السر؟',
                    wrongemailorpassword: 'يوجد خطأ في البريد الإلكتروني أو كلمة السر',

                    // LandingPage
                    letstart: 'هيا نبدأ!',
                    newwebsite: 'إنشاء موقع جديد',
                    kindofbusiness: 'نوع العمل',
                    general: 'معلومات عامة',
                    contact: 'معلومات شخصية',
                    about: 'معلومات عن الموقع',
                    portfoliowebsite: 'موقع إلكتروني',
                    store: 'محل',
                    barberorbeautysalon: 'حلاق أو صالون تجميل',
                    restaurantor_catery: 'مطعم أو محل يقدم الأكل',
                    previous: 'القسم السابق',
                    next: 'القسم التالى',
                    whatkindofbusinessareyoucreating: 'ما هو نوع العمل الذي تقوم بإنشائه ؟',
                    storelogo: 'شعار المتجر',
                    uploadlogo: 'ارفع الشعار',
                    storeinformation: 'بيانات المتجر',
                    businessname: 'إسم العمل',
                    bussinessurl: 'الرابط',
                    numberofbranches: 'عدد الفروع',
                    chooselanguage: 'إختيار اللغة',
                    personalinformarion: 'معلومات شخصية',
                    contact: 'العميل',
                    businessemail: 'بريد العمل',
                    socialmediaaccounts: 'حسابات وسائل التواصل الاجتماعي',
                    facebook: 'فيسبوك',
                    whatsapp: 'واتس أًب',
                    instagram: 'إنستجرام',
                    twitter: 'تويتر',
                    telegram: 'تلجرام',
                    about: 'لمحة',
                    address: 'العنوان',
                    name: 'الإسم',
                    features: 'المميزات',
                    // Pricing
                    egp: 'جنيه مصرى',
                    dollar: 'دولار امريكى',
                    aed: 'درهم إماراتي',
                    sale: 'خصم',
                    bestqualitywithcompatibleprices: 'افضل جوده باسعار منافسة',
                    choosewhatsuitsyou: 'إختار الباقة اللي تناسبك',
                    subscribenow: 'إشترك الان',
                    bronze: 'الخطة البرونزية',
                    silver: 'الخطة الفضية',
                    gold: 'الخطة الذهبية',
                    Platinum: 'الخطة البلاتينية',
                    exportorders: 'إستخراج الطلبيات',
                    customorderprogress: 'تحديد حالات الطلبية التى تظهر للعميل',
                    customcustomerinfo: 'بيانات مخصصة عن العميل',
                    googleanalytics: 'تحليلات جوجل',
                    customdomainname: 'إسم نطاق(Domain) مخصص',
                    support247: 'دعم طوال الإسبوع',
                    userroles: 'تحديد ادوار المستخدمين',
                    liveupdates: 'تحديثات حية',
                    onlinepayment: 'دفع إلكترونى',
                    multiplelanguages: 'لغات متعددة',
                    seo: 'تحسين محركات البحث (SEO)',
                    threelevelsofproducts: '3 مستويات للمنتجات',
                    customizedshippingrates: 'تحديد اسعار الشحن',
                    customizedpolicies: 'تحديد السياسات',
                    unlimitedpages: 'عدد لا نهائى لصفحات الويب و تطبيق الهاتف',
                    unlimitedpromocodes: 'إضافة عدد لا نهائى من كوبونات الخصم',
                    unlimitedbranches: 'إضافة عدد لا نهائى من الفروع',
                    unlimitedusers: 'إضافة عدد لا نهائى من المستخدمين',
                    unlimitedcustomers: 'إضافة عدد لا نهائى من العملاء',
                    unlimitedorders: 'إضافة عدد لا نهائى من الطلبيات',
                    unlimitedproducts: 'إضافة عدد لا نهائى من المنتجات',
                    automatedemailwithyourdomain: 'رسائل بريد الإلكتروني بإسم النطاق (Domain) الخاص بك',
                    freessl: 'شهادة SSL مجانية',
                    website: 'موقع إلكترونى',
                    androidmobileapp: 'تطبيق هاتف للاندرويد',
                    sociallogin: 'تسجيل دخول عن طريق مواقع التواصل الإجتماعى',
                    unlimitedpushnotifications: 'دفع عدد لا نهائى من Push Notifications',
                    vendors: 'الموردين',
                    smsintegration: 'SMS',
                    couriers: 'مندوبين التوصيل',
                    iosmobileapp: 'تطبيق هاتف لل IOS',
                    customermessagessystem: 'Customers Messages System',
                    smsbulk: 'SMS Bulk',
                    popupads: 'إعلانات',
                    businessemails: 'إيميلات للعمل',
                    businessmanagementmobileapp: 'تطبيق هاتف لإدارة متجرك (IOS & Android)',
                    websitebuilder: 'محاكي وباني موقعك الإلكترونى',
                    mobileappbuilder: 'محاكي وباني تطبيقات الهاتف المحمول',
                    recommended: 'موصى به',
                    pricingandpackages: 'الباقات و الأسعار',
                    // Features
                    whatmakeusspecial: 'أهم ما يميزنا',
                    onlinestoreandmobileapp: 'متجر إلكترونى و تطبيق هاتف (IOS و اندرويد)',
                    dashbaord: 'لوحة تحكم',
                    mobiledashboard: 'تطبيق هاتف للوحة التحكم',
                    websitemobileappbuilder: 'محاكي وباني موقعك الإلكترونى و تطبيق الهاتف',
                    liveupdates: 'تحديثات حيّة',
                    customizeyourpresence: 'تحكم فى متجرك الإلكترونى',
                    // iosandandroid: '',
                    // Features description
                    onlinestoreandmobileappdescription1:
                        'تابكس بتقدملك تصميمات مختلفة عشان تقدر تبنى متجرك الإلكترونى و تطبيق هاتفك زى ما تحب، بمجرد ما تسجل معانا و تختار التصميم اللى تحبه، هتقدر تبيع منتجاتك اونلاين و تستقبل فلوسك عن طريق وسائل الدفع اللى هتحددها و كمان هتقدر تختار إسم الDomain الخاص بيك',
                    dashbaorddescription1:
                        'مع تابكس هيكون عندك لوحة تحكم تقدر من خلالها تضيف كل منتجاتك بدوسة واحدة بس. و كمان هتتحكم فى المنتجات اللى هتظهر لعملائك و اسعارهم و تقدر تتحكم فى شركات الشحن و مناطق و اسعارها و تضيف كل الموردين اللى بتتعامل معاهم و تتابع كل حاجه من مكان واحد',
                    mobiledashboarddescription: `تابكس كمان بتقدملك لوحة تحكم عن طريق الهاتف عشان تسهل عليك إدارة متجرك الإلكترونى من اى مكان و فى اى وقت. مش بس كده، كمان هتقدر تشوف كل التقارير اللى هتحتاجها عشان تكبر عملك و توصل لناس اكتر من خلال الملحقات المختلفى اللى بنقدمهالك`,
                    websitemobileappbuilderdescription:
                        'مع تابكس هتقدر تتحكم فى تطبيق هاتفك و متجرك الإلكترونى؛ تضيف صفحات او تحذفها، تضيف منتجات او تحذفها، تتحكم فى التصميم عن طريق إضافة الصور و النصوص و المنتجات اللى تحبها او حتى تغيره و تختار شكل تانى عن طريق المحاكى ',
                    liveupdatesdescription: 'كل التعديلات اللى هتعملها هتقدر تشوفها فى نفس الثانية من خلال محاكى الويب او محاكى تطبيق الهاتف و هتكون متناسقة مع اى جهاز ',
                    smsintegrationdescription: 'كل طلبية هتتعمل، هتقدر ترسل رسالة نصية لعملائك بإسم شركتك لتأكيدالطلب او لإلغاء الطلب و كمان هتقدر تتحكم فى حالة الطلب اللى هتضهر لعملائك',
                    // Login
                    login: 'تسجيل الدخول',
                    signup: 'إشترك',
                    arabic: 'لغه عربيه',
                    english: 'لغه انجليزيه',
                    alreadyhaveannaccount: 'لديك حساب؟',
                    donthaveaccount: 'ليس لديك حساب؟',
                    chooseoption: 'إختر',
                    mo: 'شهر',
                    joinusnow: 'إشترك معنا الان',
                    pleasefillthemissingfields: 'من فضلك اكمل البينات',
                    industry: 'مجال العمل',
                    months: 'شهور',
                    companyname: 'اسم الشركة',
                    jobtitle: 'الوظيفة',
                    morethanthousandcustomerstrustus: 'اكثر من الف عميل  يثقون فينا',
                    foreaseofusevarietyandcustomerserviceavailablehoursaday: 'لسهولة الإستخدام و التنوع و خدمة العملاء المتاحة 24 ساعة',
                    getanonlinestoreandphoneapplicationeasily: 'انضم لعملائنا واحصل على تطبيق هاتفك و متجرك الإلكتروني في اقل من اسبوع',
                    Morethanpredesignedtemplatessoyoucanmodifythemasyoulikewithouteffort: 'اختر من بين 20 قالب مصمم مسبقاً و احصل علي موقعك وتطبيق الهاتف في خلال اسبوع   ',
                    startnow: 'ابدأ الآن',
                    getintouch: 'تواصل معنا',
                    doyouwanttolearnmoreaboutwhatwedogetintouchandwellcontactyoushortly: 'عاوز تعرف اكتر عن اللى بنقدمه؟',

                    doyouwanttolearnmoreaboutwhatwedogetintouchandwellcontactyoushortly2: 'سيبلنا بياناتك و احنا هنكلمك',

                    themostaffordableforsmallbusinessowners: ' مناسب لأصحاب الأعمال الصغيرة.',
                    everythingyouneedtoempoweryourbusiness: 'موصى بها.',
                    gotiggerneeds: ' مناسبة للشركات الكبيرة.',
                    bedifferent: 'خليك مختلف',
                    choosefromourdifferentpredesignedtemplatesandsections: 'اختر من قوالبنا و اقسامنا المختلفة المصممة سابقاً',
                    choosewhatsuitsyoufromourtemplates: 'اختر اللى يناسبك من قوالبنا',
                    invalid: 'غير صالح',
                    month: 'شهر',
                    notes: 'ملاحظات',
                    packages: 'الباقات',
                    inlessthanaweek: 'في اقل من اسبوع',
                    offerforlimitedtime: 'عرض لفترة محدودة',
                    website: 'ويب سايت',
                    webandmob: 'ويب سايت و تطبيق الهاتف',
                    companysize: 'حجم الشركة',
                    startup: 'مبتدئة',
                    small: 'صغيرة',
                    large: 'كبيرة',
                },
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
        window.location.reload();
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'ar',
            });
            document.body.setAttribute('dir', 'rtl');
        } else {
            this.setState({
                langdetect: 'ar',
            });
            document.body.setAttribute('dir', 'rtl');
            this.setlang('ar');
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        } else {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }

        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
