import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { Suspense } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import '../../trans.css';
// import Contactus from './Contactus/Contactus';
import Footer from './Footer/Footer';
import './Generalfiles/CSS_GENERAL/bootstrap.css';
import generalstyles from './Generalfiles/CSS_GENERAL/general.module.css';
import Home from './Home/Home';
import { Landingpagecontext_provider } from './Landingpagefiles/Landingpagecontext';
// import Landingpagerouter from './Landingpagefiles/Landingpagerouter';
// import Login from './Loginfiles/Login';
// import Pricing from './Pricing/Pricing';
// import Signup from './Signupfiles/Signup';
// import Termsconditions from './Termsconditions/Termsconditions';
import Websiteheader from './Websiteheader/Websiteheader';

const Termsconditions = React.lazy(() => import('./Termsconditions/Termsconditions'));
const Signup = React.lazy(() => import('./Signupfiles/Signup'));
const Pricing = React.lazy(() => import('./Pricing/Pricing'));
const Login = React.lazy(() => import('./Loginfiles/Login'));

AOS.init();

// AOS.init({ disable: 'mobile' });
const App = (props) => {
    return (
        <Router>
            <div class={generalstyles.app_container + ' w-100 '}>
                <Websiteheader />
                <Suspense fallback={<div style={{}}></div>}>
                    <div class={generalstyles.app_container_inner + ' w-100 pt-md-0 '}>
                        <Route
                            render={({ location, match }) => {
                                return (
                                    <Switch key={location.key} location={location}>
                                        <Route
                                            exact
                                            path="/"
                                            render={(props) => {
                                                return <Redirect to={`/home`} />;
                                            }}
                                        />
                                        <Route exact path="/home" component={Home} />
                                        <Route exact path="/home/pricing" component={Pricing} />
                                        <Route exact path="/home/signup" component={Signup} />
                                        <Route exact path="/home/login" component={Login} />
                                        {/* <Route exact path="/home/contactus" component={Contactus} /> */}
                                        <Route exact path="/home/termsconditions" component={Termsconditions} />

                                        {/* <Route
                                        path="/landingpage"
                                        render={() => (
                                            <Landingpagecontext_provider>
                                                <Landingpagerouter />
                                            </Landingpagecontext_provider>
                                        )}
                                    /> */}
                                    </Switch>
                                );
                            }}
                        />
                    </div>
                </Suspense>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
