import React, { useContext } from 'react';

import { AiOutlineInstagram } from '@react-icons/all-files/ai/AiOutlineInstagram';

import { HiPhone } from '@react-icons/all-files/hi/HiPhone';

import { MdEmail } from '@react-icons/all-files/md/MdEmail';
import { HiLocationMarker } from '@react-icons/all-files/hi/HiLocationMarker';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';

import { Link } from 'react-router-dom';
import { Contexthandlerscontext } from '../../../Contexthandlerscontext.js';
import { LanguageContext } from '../../../LanguageContext';

import footerstyles from './footer.module.css';
import { Loggedincontext } from '../../../Loggedincontext.js';

const Footer = (props) => {
    const { lang, setlang, langdetect } = useContext(LanguageContext);
    const { pagesarray_context, setpagesarray_context } = useContext(Contexthandlerscontext);
    const { serverbaselinkimgcontext } = useContext(Loggedincontext);
    return (
        <div class={footerstyles.footercontainer + ' row m-0 w-100 pl-md-3 pr-md-3 '}>
            <div class="col-lg-4 allcentered d-none d-md-flex mb-4">
                <Link to="/home">
                    <div class={footerstyles.footer_logo + ' allcentered '}>
                        <img src={serverbaselinkimgcontext + '/tr:w-200,h-50' + '/tabexserieswebsiteasset/tabexlogo.png'} />
                    </div>
                </Link>
            </div>
            <div class="col-lg-4 mb-md-3">
                <div class="row m-0 w-100">
                    <div class="col-lg-12 p-0 mb-3 d-flex justify-content-md-center">
                        <p class="m-0 p-0 d-flex align-items-center text-light">
                            <HiLocationMarker />
                            <span class="ml-2 mr-2 font-15">{langdetect == 'en' ? 'New Cairo, First Settlement' : 'القاهرة الجديدة، التجمع الاول'}</span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-0 mb-3 d-flex justify-content-md-center">
                        <p
                            onClick={(e) => {
                                window.location.href = 'tel:+20 127 762 7572';
                                e.preventDefault();
                            }}
                            class="m-0 p-0 d-flex align-items-center text-light text-primaryhover"
                        >
                            <HiPhone />
                            <span class="ml-2 mr-2 font-15" style={{ direction: 'ltr' }}>
                                +20 127 762 7572
                            </span>
                        </p>
                    </div>
                    <div class="col-lg-12 p-0 mb-3 d-flex justify-content-md-center">
                        <p
                            onClick={(e) => {
                                window.location.href = 'mailto:support@tabex-co.com';
                                e.preventDefault();
                            }}
                            class="m-0 p-0 d-flex align-items-center text-light text-primaryhover"
                        >
                            <MdEmail />
                            <span class="ml-2 mr-2 font-15">support@tabex-co.com</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 allcentered d-flex d-md-none">
                <Link to="/home">
                    <div class={footerstyles.footer_logo + ' allcentered '}>
                        <img src={serverbaselinkimgcontext + '/tr:w-200,h-50' + '/tabexserieswebsiteasset/tabexlogo.png'} />
                    </div>
                </Link>
            </div>
            <div class="col-lg-4 d-flex align-items-center justify-content-end justify-content-md-center">
                <Link to={{ pathname: 'https://www.facebook.com/tabexseries/' }} target="_blank">
                    <div class={langdetect == 'en' ? footerstyles.footericon + ' mr-3 ' : footerstyles.footericon + ' ml-3 '}>
                        <i class="h-100 allcentered">
                            <FaFacebookF size={18} />
                        </i>
                    </div>
                </Link>
                <Link to={{ pathname: 'https://instagram.com/tabex.series?igshid=YmMyMTA2M2Y=' }} target="_blank">
                    <div class={langdetect == 'en' ? footerstyles.footericon + ' mr-3 ' : footerstyles.footericon + ' ml-3 '}>
                        <i class="h-100 allcentered">
                            <AiOutlineInstagram size={18} />
                        </i>
                    </div>
                </Link>

                {/* <div class={footerstyles.footericon}>
                    <i class="h-100 allcentered">
                        <BsWhatsapp size={20} />
                    </i>
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
