import React, { Suspense, useEffect } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { Contexthandlerscontext_provider } from './Contexthandlerscontext';
import { Loggedincontext } from './Loggedincontext.js';
import { Routedispatcherprovider } from './Routedispatcher';
import { fetchuseauthorization } from './components/API/Login_API';
import './trans.css';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import Websiterouter from './components/Website/Websiterouter';
// const Websiterouter = React.lazy(() => import('./components/Website/Websiterouter'));

const App = (props) => {
    let history = useHistory();
    const { userloggedinfobjcontext, setuserloggedinfobjcontext, setserverbaselinkimgcontext, serverbaselinkimgcontext, setisloggedincontext } = React.useContext(Loggedincontext);
    const fetchuseauthorizationQuery = useQuery(['fetchuseauthorization'], () => fetchuseauthorization(), { keepPreviousData: true, staleTime: 500000000000000000 });
    useEffect(() => {
        if (fetchuseauthorizationQuery.isSuccess) {
            setisloggedincontext(fetchuseauthorizationQuery.data.data.loggedin);
            setserverbaselinkimgcontext(fetchuseauthorizationQuery.data.data.imurl);
            if (fetchuseauthorizationQuery.data.data.loggedin) {
                var tempuserloggedinfo = { ...userloggedinfobjcontext };
                tempuserloggedinfo.userinfo = { ...fetchuseauthorizationQuery.data.data.userinfo };
                setuserloggedinfobjcontext({
                    ...tempuserloggedinfo,
                });
            }
        }
    }, [fetchuseauthorizationQuery.isSuccess]);

    return (
        <Contexthandlerscontext_provider>
            <Router>
                <Routedispatcherprovider>
                    <div style={{ height: '100%', width: '100%', background: 'white' }}>
                        <Route
                            render={({ location, match }) => {
                                return (
                                    <TransitionGroup>
                                        <Switch key={location.key} location={location}>
                                            <Route
                                                path="/"
                                                render={() => {
                                                    return <Websiterouter />;
                                                }}
                                            />
                                        </Switch>
                                    </TransitionGroup>
                                );
                            }}
                        />
                    </div>
                </Routedispatcherprovider>
            </Router>
            <NotificationContainer />
            <ReactQueryDevtools initialIsOpen />
        </Contexthandlerscontext_provider>
    );
};

export default App;
