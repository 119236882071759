import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
export const Contexthandlerscontext = React.createContext();
export const Contexthandlerscontext_provider = (props) => {
    let history = useHistory();
    const [pagesarray_context, setpagesarray_context] = useState([
        {
            pagename_en: 'Home',
            pagename_ar: 'الرئيسية',
            isselected: false,
            reactpath: '/home',
        },
        {
            pagename_en: 'Pricing',
            pagename_ar: 'الأسعار',
            isselected: false,
            reactpath: '/home/pricing',
        },
        // {
        //     pagename_en: 'Contact us',
        //     pagename_ar: 'تواصل معنا',
        //     isselected: false,
        //     reactpath: '/home/contactus',
        // },
    ]);
    const [pagetitle_context, setpagetitle_context] = useState('');
    const setpageactive_context = (route) => {
        var temparr = [...pagesarray_context];
        temparr.forEach((sideelement, index) => {
            if (sideelement.reactpath == route) {
                sideelement['isselected'] = true;
            } else {
                sideelement['isselected'] = false;
            }
        });
        setpagesarray_context([...temparr]);
    };
    return (
        <Contexthandlerscontext.Provider
            value={{
                pagesarray_context,
                setpagesarray_context,
                pagetitle_context,
                setpagetitle_context,
                setpageactive_context,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
