import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { AiOutlineUser } from '@react-icons/all-files/ai/AiOutlineUser';
import { BiChevronDown } from '@react-icons/all-files/bi/BiChevronDown';
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';

import { Link, useHistory } from 'react-router-dom';
import { Contexthandlerscontext } from '../../../Contexthandlerscontext.js';
import { LanguageContext } from '../../../LanguageContext';
import { Loggedincontext } from '../../../Loggedincontext.js';
import '../Generalfiles/CSS_GENERAL/dropdown.css';
import { Offcanvas } from 'react-bootstrap';

import headerstyles from './header.module.css';
const Websiteheader = (props) => {
    let history = useHistory();
    const { lang, setlang, langdetect } = useContext(LanguageContext);

    const { isloggedincontext, userloggedinfobjcontext, serverbaselinkimgcontext, hidesignupandpricingContext } = useContext(Loggedincontext);
    const { pagesarray_context, setpagesarray_context } = useContext(Contexthandlerscontext);
    const [navbarOpen, setNavbarOpen] = useState(false);
    useEffect(() => {}, []);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 50);
        });
    }, []);
    const [header2, setheader2] = useState(false);
    useEffect(() => {
        if (history.location.pathname == '/home/signup' || history.location.pathname == '/home/login') {
            setheader2(true);
        } else {
        }
        history.listen(() => {
            if (history.location.pathname == '/home/signup' || history.location.pathname == '/home/login') {
                setheader2(true);
            } else {
            }
            window.scrollTo(0, 0);
        });
    }, [history]);
    return (
        <div class={scroll ? `${headerstyles.app_header} ${headerstyles.app_header_scrolled}` + ' d-lg-flex ' : headerstyles.app_header + ' d-lg-flex '}>
            <div class={headerstyles.app_header__content}>
                <div class="row w-100 m-0">
                    <div class="col-md-3 col-sm-3 d-none d-md-flex justify-content-start align-items-center pt-1">
                        <div style={{ direction: langdetect == 'en' ? 'ltr' : 'rtl' }} class={langdetect == 'en' ? 'dropdowncontainerrespar' : 'dropdowncontainerresp'}>
                            <div
                                onClick={() => {
                                    if (langdetect == 'en') {
                                        setlang('ar');
                                    } else {
                                        setlang('en');
                                    }
                                }}
                                class={headerstyles.languagecontainer + ' d-flex align-items-center '}
                                style={{ boxShadow: header2 == true ? 'none' : '' }}
                            >
                                {langdetect == 'en' && (
                                    <div class="allcentered w-100">
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/usflag.png'} style={{ maxWidth: '15px', maxHeight: '15px' }} class="ml-md-1 mr-md-1" />
                                        <div style={{ width: '1px', height: '20px', background: '#ccc' }} class="ml-2 mr-2 d-md-none" />
                                        En
                                    </div>
                                )}
                                {langdetect == 'ar' && (
                                    <div class="allcentered w-100">
                                        ع
                                        <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/egyptflag.png'} style={{ maxWidth: '15px', maxHeight: '15px' }} class="mr-1 ml-1" />
                                        <div style={{ width: '1px', height: '20px', background: '#ccc' }} class="ml-2 mr-2 d-md-none" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 mt-1 pl-2 p-0 d-flex align-items-center justify-content-sm-center">
                        <Link to="/home" class="d-flex justify-content-md-center">
                            <div class={headerstyles.header_logo + ' d-flex allcentered align-items-sm-center '}>
                                <img
                                    style={{ width: '200px', height: '80px', objectFit: 'contain' }}
                                    src={serverbaselinkimgcontext + '/tr:w-200,h-50/' + '/tabexserieswebsiteasset/tabexlogo.png'}
                                    class={'allcentered'}
                                />
                            </div>
                        </Link>
                    </div>
                    <div class="col-md-3 col-sm-3 d-none d-md-flex justify-content-end align-items-center  pt-1">
                        <div
                            style={{ direction: langdetect == 'en' ? 'ltr' : 'rtl' }}
                            class={langdetect == 'en' ? 'dropdowncontainerresp d-none d-md-flex align-items-center ' : ' dropdowncontainerresp d-none d-md-flex align-items-center'}
                        >
                            <div
                                onClick={() => {
                                    setNavbarOpen(true);
                                }}
                                class={' d-flex align-items-center pt-1'}
                            >
                                <i
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        backgroundColor: 'var(--primary)',
                                        borderRadius: '100px',
                                    }}
                                    class="allcentered bg-secondaryhover"
                                >
                                    <AiOutlineMenu size={20} style={{ color: 'white' }} />
                                </i>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-lg-4 col-md-2 d-flex d-md-none align-items-center justify-content-center d-flex d-md-none"
                        style={{ position: 'relative', direction: langdetect == 'en' ? 'ltr' : 'rtl' }}
                    >
                        {pagesarray_context.map((item, index) => {
                            var activeclass = '';
                            if (item.isselected) {
                                activeclass = headerstyles.navlinkactive;
                            }
                            var show = true;
                            if (item.pagename_en == 'Pricing') {
                                if (hidesignupandpricingContext) {
                                    show = false;
                                }
                            }
                            if (show) {
                                return (
                                    <Link to={item.reactpath}>
                                        <p class={headerstyles.navlink + ' mb-0 pb-0 ' + activeclass} style={{ fontSize: langdetect == 'en' ? '16px' : '17px' }}>
                                            {langdetect == 'en' ? item.pagename_en : item.pagename_ar}
                                        </p>
                                    </Link>
                                );
                            }
                        })}
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 p-0 d-flex d-md-none align-items-center justify-content-end">
                        {!isloggedincontext && (
                            <Link to="/home/signup" class={headerstyles.btnblobputercont + ' mb-sm-3 '}>
                                <button
                                    class={
                                        langdetect == 'en'
                                            ? headerstyles.createwebsitebtn + ' font-14 font-sm-12 pt-sm-2 pb-sm-2 pl-sm02 pr-sm-2 '
                                            : headerstyles.createwebsitebtn + ' font-15 font-sm-12 '
                                    }
                                >
                                    {lang.createyouronlinestorenow}
                                    <span class={headerstyles.createwebsitebtn__inner}>
                                        <span class={headerstyles.createwebsitebtn__blobs}>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                        </span>
                                    </span>
                                </button>
                                <br />
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <defs>
                                        <filter id="goo">
                                            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                                            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                        </filter>
                                    </defs>
                                </svg>
                            </Link>
                        )}
                        {!isloggedincontext && (
                            <Link to="/home/login">
                                <div class={headerstyles.loginbtn + ' d-flex cursor-pointer allcentered mr-3 ml-3 '}>
                                    <p class="m-0 p-0 text-uppercase poppinssemibold" style={{ letterSpacing: langdetect == 'en' ? '1px' : 0 }}>
                                        {lang.login}
                                    </p>
                                </div>
                            </Link>
                        )}
                        {isloggedincontext && (
                            <div class={' ml-3 mr-3 d-flex p-2 align-items-center justify-content-sm-end cursor-pointer p-sm-0 ml-sm-1 mr-sm-1 '} style={{ width: 'auto' }}>
                                <a href="https://dashboard.tabexseries.com" class={headerstyles.loggedincontainer + ' d-flex align-items-center '}>
                                    <div class={headerstyles.loggedinicon + ' ml-1 mr-1 d-sm-none '}>
                                        <i class="h-100 d-flex justify-content-center align-items-center">
                                            <AiOutlineUser size={18} />
                                        </i>
                                    </div>
                                    <i class="h-100 d-none d-sm-flex justify-content-center align-items-center ml-1 mr-1">
                                        <AiOutlineUser size={15} />
                                    </i>
                                    <p class="m-0 p-0 text-capitalize font-weight-500 font-14 text-overflow loggedinnname">{userloggedinfobjcontext.userinfo.name}'s Dashboard</p>
                                </a>
                            </div>
                        )}
                        <div style={{ direction: langdetect == 'en' ? 'ltr' : 'rtl' }} class={langdetect == 'en' ? 'dropdowncontainer d-sm-none' : 'dropdowncontainer_translated d-sm-none'}>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <div class={headerstyles.languagecontainer + ' d-flex align-items-center '} style={{ boxShadow: header2 == true ? 'none' : '' }}>
                                        {langdetect == 'en' && (
                                            <div class="allcentered w-100">
                                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/usflag.png'} style={{ maxWidth: '15px', maxHeight: '15px' }} class="ml-md-1 mr-md-1" />
                                                <div style={{ width: '1px', height: '20px', background: '#ccc' }} class="ml-2 mr-2 d-md-none" />
                                                <i class="h-100 allcentered">
                                                    <BiChevronDown size={20} />
                                                </i>
                                            </div>
                                        )}
                                        {langdetect == 'ar' && (
                                            <div class="allcentered w-100">
                                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/egyptflag.png'} style={{ maxWidth: '15px', maxHeight: '15px' }} />
                                                <div style={{ width: '1px', height: '20px', background: '#ccc' }} class="ml-2 mr-2 d-md-none" />
                                                <i class="h-100 allcentered">
                                                    <BiChevronDown size={20} />
                                                </i>
                                            </div>
                                        )}
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setlang('ar');
                                        }}
                                    >
                                        <div class="row m-0 w-100 d-flex align-items-center">
                                            <div class="col-lg-10 col-md-10 col-sm-10 p-0 d-flex justify-content-start">
                                                <p class="m-0 p-0">{lang.arabic}</p>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 p-0 d-flex justify-content-end">
                                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/egyptflag.png'} style={{ maxWidth: '15px', maxHeight: '15px' }} />
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={() => {
                                            setlang('en');
                                        }}
                                    >
                                        <div class="row m-0 w-100 d-flex align-items-center">
                                            <div class="col-lg-10 col-md-10 col-sm-10  p-0 d-flex justify-content-start">
                                                <p class="m-0 p-0">{lang.english}</p>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 p-0 d-flex justify-content-end">
                                                <img src={serverbaselinkimgcontext + '/tabexserieswebsiteasset/usflag.png'} style={{ maxWidth: '15px', maxHeight: '15px' }} />
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div class="col-lg-12 p-0 d-none d-md-flex justify-content-md-center mt-1" style={{ direction: langdetect == 'en' ? 'ltr' : 'rtl' }}>
                        {!isloggedincontext && (
                            <Link to="/home/signup" class={headerstyles.btnblobputercont + ' d-md-none'}>
                                <button class={langdetect == 'en' ? headerstyles.createwebsitebtn + ' font-14 font-sm-12  ' : headerstyles.createwebsitebtn + ' font-15 '}>
                                    {lang.createyouronlinestorenow}
                                    <span class={headerstyles.createwebsitebtn__inner}>
                                        <span class={headerstyles.createwebsitebtn__blobs}>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                            <span class={headerstyles.createwebsitebtn__blob}></span>
                                        </span>
                                    </span>
                                </button>
                                <br />
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                    <defs>
                                        <filter id="goo">
                                            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                                            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                        </filter>
                                    </defs>
                                </svg>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <Offcanvas
                show={navbarOpen}
                onHide={() => {
                    setNavbarOpen(false);
                }}
                placement={langdetect == 'ar' ? 'end' : 'start'}
                scroll={true}
            >
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        <div class={' row m-0 d-flex  '} style={{ minHeight: '100vh', backgroundColor: 'white', width: '360px', position: 'relative' }}>
                            <div
                                onClick={() => {
                                    setNavbarOpen(false);
                                }}
                                class={'allcentered'}
                                style={{
                                    position: 'absolute',
                                    left: langdetect == 'en' ? '' : -45,
                                    right: langdetect == 'ar' ? '' : -45,
                                    top: 10,
                                    zIndex: 10000,
                                    background: 'var(--primary)',
                                    width: '35px',
                                    height: '35px',
                                }}
                            >
                                <AiOutlineClose color="white" size={15} />
                            </div>

                            <div class="col-lg-12 p-0 d-flex mt-4">
                                <div class="row m-0 w-100">
                                    <div class={headerstyles.header_logo + ' d-flex w-100 allcentered align-items-sm-center mb-4 '}>
                                        <img
                                            style={{ width: '200px', height: '80px', objectFit: 'contain' }}
                                            src={serverbaselinkimgcontext + '/tr:w-200,h-50/' + '/tabexserieswebsiteasset/tabexlogo.png'}
                                            class={'allcentered'}
                                        />
                                    </div>
                                    {pagesarray_context.map((item, index) => {
                                        var activeclass = '';
                                        if (item.isselected) {
                                            activeclass = headerstyles.navlinkactive;
                                        }
                                        var show = true;
                                        if (item.pagename_en == 'Pricing') {
                                            if (hidesignupandpricingContext) {
                                                show = false;
                                            }
                                        }
                                        if (show) {
                                            return (
                                                <div class="col-lg-12 p-0 d-flex">
                                                    <div class="row m-0 w-100">
                                                        <div
                                                            class="col-lg-12 allcentered p-0 mt-2 mb-2"
                                                            style={{
                                                                textAlign: langdetect == 'en' ? 'left' : 'right',
                                                                fontWeight: langdetect == 'en' ? 500 : 700,
                                                            }}
                                                        >
                                                            <Link
                                                                onClick={() => {
                                                                    setNavbarOpen(false);
                                                                }}
                                                                to={item.reactpath}
                                                            >
                                                                <p class={' mb-0 pb-0 ' + activeclass} style={{ fontSize: langdetect == 'en' ? '17px' : '16px' }}>
                                                                    {langdetect == 'en' ? item.pagename_en : item.pagename_ar}
                                                                </p>
                                                            </Link>
                                                        </div>
                                                        {index != pagesarray_context?.length - 1 && (
                                                            <div class="col-lg-12 px-4 my-3">
                                                                <hr />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                    <div class="col-lg-12 p-0 mt-3">
                                        <div class="row m-0 w-100">
                                            {!isloggedincontext && (
                                                <div class="col-lg-12 d-flex allcentered mt-5 mt-md-2 mb-md-3 mt-sm-4 mb-sm-4 p-0">
                                                    <button
                                                        onClick={() => {
                                                            history.push('/home/login');
                                                            setNavbarOpen(false);
                                                        }}
                                                        style={{
                                                            width: '55%',
                                                            height: '45px',
                                                            fontSize: '15px',
                                                        }}
                                                        class={
                                                            `${headerstyles.generalhomebutton}` +
                                                            ' col-md-10 bg-primary bg-secondaryhover text-white d-flex justify-content-center align-items-center  '
                                                        }
                                                    >
                                                        {lang.login}
                                                    </button>
                                                </div>
                                            )}

                                            {!isloggedincontext && hidesignupandpricingContext == false && (
                                                <div class="col-lg-12 d-flex allcentered mt-5 mt-md-2 mb-md-3 m-sm-0 p-0">
                                                    <button
                                                        onClick={() => {
                                                            history.push('/home/signup');
                                                            setNavbarOpen(false);
                                                        }}
                                                        style={{
                                                            width: '55%',
                                                            height: '45px',
                                                            fontSize: '15px',
                                                        }}
                                                        class={
                                                            `${headerstyles.generalhomebutton}` +
                                                            ' col-md-10 bg-primary bg-secondaryhover text-white d-flex justify-content-center align-items-center  '
                                                        }
                                                    >
                                                        {lang.signup}
                                                    </button>
                                                </div>
                                            )}
                                            {isloggedincontext && (
                                                <div class="col-lg-12 d-flex allcentered mt-5 mt-md-2 mb-md-3 mt-sm-5 mb-sm-5 p-0">
                                                    <a
                                                        href="https://dashboard.tabexseries.com"
                                                        onClick={() => {
                                                            setNavbarOpen(false);
                                                        }}
                                                        style={{
                                                            width: '55%',
                                                            height: '45px',
                                                            fontSize: '15px',
                                                        }}
                                                        class={
                                                            `${headerstyles.generalhomebutton}` +
                                                            ' col-md-10 bg-primary bg-secondaryhover text-white d-flex justify-content-center align-items-center  '
                                                        }
                                                    >
                                                        {userloggedinfobjcontext.userinfo.name}'s Dashboard
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* {!isloggedincontext && (
                                        <>


                                            <div class="col-lg-12 p-0 mt-0 mb-1">
                                                <hr class="m-0" />
                                            </div>
                                            <Link to={'/home/login'}>
                                                <p class={' mb-0 pb-0 '} style={{ fontSize: langdetect == 'en' ? '15px' : '16px', textAlign: langdetect == 'en' ? 'left' : 'right' }}>
                                                    {lang.login}
                                                </p>
                                            </Link>
                                        </>
                                    )} */}

                                    <div class={'col-md-12 h-100 mt-1 p-0'}></div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body></Offcanvas.Body>
            </Offcanvas>

            {/* <MessengerCustomerChat pageId="110775398339045" appId="<APP_ID>" htmlRef="<REF_STRING>" /> */}
        </div>
    );
};

export default Websiteheader;
