export const defaultstyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #b1b3b6',
        backgroundColor: state.isSelected ? '' : '',
        padding: 5,
        textTransform: 'capitalize',
        color: 'var(--greenprimary)',
        transition: '.3s',
        '&:hover': {
            // color: '#eac435',
            cursor: 'pointer',
            color: 'var(--secondary)',
        },
        '&:focus': {
            // color: '#eac435',
            cursor: 'pointer',
            color: 'var(--secondary)',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        color: 'var(--text)',
        border: '1px solid #E9E9E9',
        borderRadius: '4px',
        backgroundColor: 'transparent',
        display: 'flex',
        cursor: 'pointer',
        padding: 0,
        transition: '.3s',
        borderColor: state.isFocused ? 'var(--greenprimary) !important' : '',
        borderBottomLeftRadius: state.isFocused ? '0px' : '',
        borderBottomRightRadius: state.isFocused ? '0px' : '',
        marginTop: '15px',
        height: '44px',
        position: 'relative',
        boxShadow: '0px !important',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'var(--priamry)',

        transform: state.isFocused ? 'rotate(180deg)' : '',
        fontSize: '11px !important',
        width: '30px',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: 'visible',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        textAlign: 'start',
        color: 'var(--text) !important',
        position: 'absolute',
        zIndex: 10,
        top: state.hasValue || state.selectProps.inputValue ? -20 : '',
        transition: 'top 0.1s, font-size 0.1s',
        fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 14,
        backgroundColor: 'white',
    }),
    input: (provided) => ({
        ...provided,
        color: 'var(--text)',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--priamry)',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 105,
        color: 'var(--priamry)',
        top: '31px',
        borderRadius: '0px',
        border: '0px',
        boxShadow: '0 8px 24px rgba(57,68,104,0.08) !important',
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px',
        border: '0px',
        boxShadow: '0px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: 'var(--text)',
        backgroundColor: 'transparent !important',
        border: '0px',
        boxShadow: '0px',
        textAlign: 'start',
        cursor: 'pointer',
        '&:selection': {
            backgroundColor: 'transparent !important',
        },
    }),
};

export const defaultstyles1 = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '0px',
        backgroundColor: 'transparent',
        padding: '0px',
        textTransform: 'capitalize',
        color: 'var(--greenprimary)',
        transition: '.3s',
        fontSize: '12px !important',
        '&:hover': {
            // color: '#eac435',
            cursor: 'pointer',
            color: 'var(--secondary)',
        },
        '&:focus': {
            // color: '#eac435',
            cursor: 'pointer',
            color: 'var(--secondary)',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        color: 'var(--priamry)',
        border: '0px',
        borderRadius: '0px',
        backgroundColor: 'transparent',
        display: 'flex',
        cursor: 'pointer',
        padding: 0,
        transition: '.3s',
        borderColor: state.isFocused ? 'var(--greenprimary) !important' : '',
        borderBottomLeftRadius: state.isFocused ? '0px' : '',
        borderBottomRightRadius: state.isFocused ? '0px' : '',
        marginTop: '0px',
        fontSize: '14px',
        boxShadow: '0px !important',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'var(--priamry)',
        transition: '.3s',
        transform: state.isFocused ? 'rotate(180deg)' : '',
        fontSize: '11px !important',
        width: '30px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        textAlign: 'start',
        // color: 'var(--priamry)',
    }),
    input: (provided) => ({
        ...provided,
        color: 'var(--priamry)',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--priamry)',
    }),
    menu: (provided) => ({
        ...provided,
        color: 'var(--priamry)',
        top: '31px',
        borderRadius: '0px',
        border: '0px',
        boxShadow: '0 8px 24px rgba(57,68,104,0.08) !important',
    }),
    MenuList: (provided) => ({
        ...provided,

        border: '0px',
        boxShadow: '0px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: 'var(--text)',
        backgroundColor: 'transparent !important',
        border: '0px',
        boxShadow: '0px',
        cursor: 'pointer',
        '&:selection': {
            backgroundColor: 'transparent !important',
        },
    }),
};

export const defaultstyles2 = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #b1b3b6',
        backgroundColor: state.isSelected ? '' : '',
        padding: 5,
        textTransform: 'capitalize',
        color: 'var(--greenprimary)',
        transition: '.3s',
        '&:hover': {
            // color: '#eac435',
            cursor: 'pointer',
            color: 'var(--secondary)',
        },
        '&:focus': {
            // color: '#eac435',
            cursor: 'pointer',
            color: 'var(--secondary)',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        color: 'var(--text)',
        borderRadius: '0px',
        border: '0px',
        borderBottom: '1px solid #E9E9E9',
        backgroundColor: 'transparent',
        display: 'flex',
        cursor: 'pointer',
        transition: '.3s',
        borderColor: state.isFocused ? 'var(--greenprimary) !important' : '',
        borderBottomLeftRadius: state.isFocused ? '0px' : '',
        borderBottomRightRadius: state.isFocused ? '0px' : '',
        // marginTop: '6px',
        paddingTop: '15px',
        height: '57px',
        position: 'relative',
        boxShadow: '0px !important',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: 'var(--priamry)',
        transform: state.isFocused ? 'rotate(180deg)' : '',
        fontSize: '11px !important',
        width: '30px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        textAlign: 'start',
        color: 'var(--text) !important',
        position: 'absolute',
        zIndex: 10,
        top: state.hasValue || state.selectProps.inputValue ? -20 : '',
        transition: 'top 0.1s, font-size 0.1s',
        fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 14,
        backgroundColor: 'white',
    }),
    input: (provided) => ({
        ...provided,
        color: 'var(--text)',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--priamry)',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 105,
        color: 'var(--priamry)',
        top: '40px',
        borderRadius: '0px',
        border: '0px',
        boxShadow: '0 8px 24px rgba(57,68,104,0.08) !important',
    }),
    MenuList: (provided) => ({
        ...provided,

        border: '0px',
        boxShadow: '0px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: 'var(--text)',
        backgroundColor: 'transparent !important',
        border: '0px',
        boxShadow: '0px',
        cursor: 'pointer',
        '&:selection': {
            backgroundColor: 'transparent !important',
        },
    }),
};
